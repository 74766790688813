@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
  height: 100%;
}

.app {
  height: 100%;
}

body {
  background: #EFE8DF;
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat Medium"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Italic";
  src: local("Montserrat Italic"),
    url(./assets/fonts/Montserrat-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"),
    url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat BoldItalic";
  src: local("Montserrat BoldItalic"),
    url(./assets/fonts/Montserrat-BoldItalic.ttf) format("truetype");
}

h1 {
  font-size: 48px;
  font-family: 'Montserrat Bold';
}

.wrapper {
  width: 100%;
  height: 100%;
}

.noscrollcontainer {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.noscrollcontainer::-webkit-scrollbar {
  display: none !important;
}